// MARK: Resouces
import { currencyForBR } from "../../resources/format";

export const components = {
	ptBR: {
		navbar: {
			buttons: {
				chat: "Conversas",
				favorite: "Favoritos",
				profile: "Perfil",
				cart: "Carrinho",
				myStore: "Minha Loja",
				Menu: "Menu",
				orders: "Pedidos",
				sales: "Vendas",
			},
			search: "Buscar produto...",
			general: "Geral",
			welcomeTexts: {
				welcomeWithUser: {
					text: (currentUserName: string) =>
						`Olá, ${currentUserName}`,
					link: "Minha conta",
				},
				welcomeWithoutUser: {
					text: "Olá, bem-vindo",
					link: "entre / cadastre-se",
				},
			},
		},
		contactForm: {
			name: "Nome",
			email: "Email",
			phone: "Telefone",
			subject: "Assunto",
			deadLine: "Prazo",
			budget: "Orçamento",
			message: "Mensagem",
		},
		footer: {
			titlePage: "Necessial",
			address: {
				step1: "123 Mockup Street",
				step2: "San Francisco, CA 10000",
				step3: "(555) 5555-555",
				copyright: "Copyright © Startapp All right reserved.",
			},
		},
		favoriteButton: {
			favoriteButtonText: (isFavorite: boolean) =>
				isFavorite
					? "Remover dos favoritos"
					: "Adicionar aos favoritos",
		},
		statusBar: {
			status: {
				analysing: "Em análise",
				acceptPayment: "Pagamento aprovado",
				production: "Em produção",
				onWay: "A caminho",
				delivered: "Entregue",
				canceled: "Cancelado",
			},
		},
		productsCard: {
			unitary: "/ un",
			seeAll: "Ver todos",
		},
		cartContainer: {
			store: "LOJA",
			products: "PRODUTOS",
			talkWithStore: "Entre em contato",
			talkWithSupport: "Entre em contato com suporte.",
			subTotal: "Subtotal",
			sendRequestStore: "Enviar pedido a Loja",
			productCart: {
				productUnits: (price: number) => `${currencyForBR(price)} / un`,
				subtract: "-",
				add: "+",
				remove: "Remover",
			},
		},
		sideOptions: {
			search: "BUSCA",
			store: "LOJA",
			category: "CATEGORIA",
			selectedTags: "TAGS SELECIONADAS",
			othersTags: "Outras tags",
			orderBy: "Ordenar por:",
			orderOptions: {
				highestPrice: "Maior preço",
				lowerstPrice: "Menor preço",
			},
			selectOptions: {
				null: "Todos",
				clothes: "Roupas",
				accessories: "Acessórios",
				sport: "Idoso",
				toys: "Infantil",
				party: "Casa",
				educational: "PCD",
				health: "Bem-estar",
				customized: "Outros",
			},
		},
		searchPagination: {
			search: (lenght: number) => {
				return `${lenght} Resultados`;
			},
			page: (currentPage: number) => {
				return `Página ${currentPage}`;
			},
			errorBack: "Não é possível voltar mais páginas",
			errorNext: "Não há mais páginas",
		},
		chipsArray: {
			close: "Fechar",
			save: "Salvar",
			choiceAllTags: "Escolha tags para seu produto",
			added: "Adicionar",
		},
		buyInfo: {
			titleDialog: "Adicionar ao carrinho",
			message:
				"Antes de prosseguir adicione a quantidade que deseja deste produto",
		},
		about: {
			payment: {
				title: "Compra segura e envio para todo o Brasil",
				description: "Pagamento via boleto ou cartão de crédito em até 12x ",
			},
			shipping: {
				title: "Abra a sua loja sem custo",
				description: "Cadastro simples e rápido",
			},
			custom: {
				title: "Produtos selecionados",
				description: "Produtos essenciais para o seu dia a dia",
			},
			knowMore: {
				title: "Conheça Necessial",
				description: "Saiba mais sobre nós",
			},
		},
		transferDialog: {
			transfer: "Transferência",
			infoAmount: "Informe o valor que deseja sacar",
		},
	},
};
