export const error = {
	ptBR: {
		needLongerText: (numOfChars: number) =>
			"Precisa ser maior que " + numOfChars + " caracteres.",
		default: "Um erro ocorreu, tente mais tarde.",
		pageNotFound: "Página não encontrada",
		errorTitleProfile: "Página de perfil indisponível",
		errorDescriptionProfile:
			"Ocorreu um erro ao tentarmos abrir a página de perfil. Por favor, tente novamente mais tarde.",
		invalidImage: "Por favor, adicione uma imagem!",
		pageNotFoundDescription:
			"Essa página não foi encontrada, ou não existe mais. Tente novamente mais tarde.",
		redirect: "Você será redirecionado para página principal.",
		needValidName: "Preencha o campo de NOME corretamente",
		invalidEmail: "Por favor, informe um e-mail válido.",
		invalidPhone: "Por favor, informe um número válido.",
		invalidCategory: "Adicione uma categoria.",
		invalidTrackingCode: "Código de rastreio inválido",
		passwordsDoesNotMatch: "As senha não conferem",
		tryAgainLater: "Tente novamente mais tarde.",
		tokenNotFound: "Não foi possível encontrar o token",
		noResults: "Escolha outra palavra ou navegue por nossas categorias.",
		noResultsFor: "Sem resultados para",
		invalidCard: "Cartão inválido.",
		deleteCard: "Ocorreu um erro ao deletar o cartão.",
		loadInfo: "Ocorreu um erro ao carregar as informações.",
		emptyTrackingCode: "A escolha do frete é obrigatória",
		isNotChecked: "Por favor, leia e concorde com os nossos Termos de Uso",
		emptyProductsList: "Produto não encontrado",
	},
};
