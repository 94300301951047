// MARK: Resources
import { IProps as OgTagsProps } from "../../OgTags";

// MARK: Implementation
export const home = {
	ptBR: {
		ogTags: {
			type: "website",
			title: "Necessial",
			themeColor: "#576D6E",
			keywords: [
				"ecommerce",
				"produtos essenciais",
				"Progressive Web App",
				"necessidades especiais",
			],
			twitter: {
				type: "summary",
			},
			image: {
				url: `https://${
					process.env.NEXT_PUBLIC_PAGE_URL || "necessial.com.br"
				}/necessial_image_logo.png`,
				type: "image/png",
				width: 1024,
				height: 480,
			},
		} as Omit<Omit<OgTagsProps, "strings">, "url">,
	},
};
