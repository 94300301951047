// MARK: Libraries
import { ptBR } from "date-fns/locale";

import moment from "moment";
moment.locale("pt-br");

export const project = {
	ptBR: {
		projectName: "necessial",
		categories: {
			clothes: "Roupas",
			accessories: "Acessórios",
			sport: "Idoso",
			toys: "Infantil",
			party: "Casa",
			educational: "PCD",
			health: "Bem-estar",
			customized: "Outros",
		},
		total: "Total",
		ourTeam: "Nosso time",
		product: "Produto",
		chatRooms: "Conversas",
		subTotal: "Subtotal",
		cart: "Carrinho",
		quantity: "Quant.",
		changeInfoToBecomeAStore:
			"Prossiga o cadastro abaixo e torne-se um vendedor Necessial!",
		becomeAStoreExplanation:
			"Com uma conta Loja, na plataforma Necessial, você adquire a possibilidade de vender seus incríveis produtos!",
		changeNameToBecomeStore:
			"Altere seu nome e nick, caso seja conveniente para a loja",
		howTheIdeaWasCreated: "Como a ideia surgiu?",
		purchases: "Compras",
		titleOrder: (id: string) => `Pedido ${id}`,
		titleSale: (id: string) => `Venda ${id}`,
		dateBuy: `Data do pedido`,
		dayToDeliveryTitle: (daysToDelivery) =>
			`${daysToDelivery} ${daysToDelivery <= 1 ? "dia" : "dias"} ${daysToDelivery <= 1 ? "útil" : "úteis"
			}*`,
		emptyOrderUserHistory: "Você não possui pedidos no seu histórico",
		emptyOrderStoreHistory: "Você não possui vendas no seu histórico",
		orders: "Pedidos",
		order: "Pedido",
		turnIntoSeller: "Torne-se um vendedor!",
		changeStatusSuccess: "Mudança de status realizada com sucesso!",
		ratingSuccess: "Sua avaliação foi enviada!",
		cancelOrderSuccess: "Cancelado com sucesso!",
		orderValue: "Valor do pedido",
		orderDate: "Data do pedido",
		productDetails: "Informações",
		payment: "Pagamento",
		paymentBy: "Pagamento por ",
		sales: "Vendas",
		boleto: "Boleto",
		productPrice: "Preço do produto",
		storeUserTerms: "Termos de Uso da Loja",
		soldBy: "Vendido por",
		trackingCode: "Código de Rastreio",
		track: "Rastrear",
		shipping: "Frete",
		cancelOrder: "Cancelar pedido",
		storeContact: (slug: string) => `@${slug}`,
		aboutNecessialTitle: "Sobre nós",
		aboutNecessialDescription: `A Necessial é um marketplace focado na inclusão e representatividade, voltado para pessoas com deficiência e que têm necessidades específicas. Na nossa plataforma, você encontra em um único lugar, produtos essenciais para o seu dia a dia, lojas inclusivas, dicas de profissionais e uma rede de apoio, tudo com uma curadoria de marcas, profissionais qualificados e atendimento humanizado.`,
		aboutNecessialDescription2: `Somos um negócio de impacto social, então comprando e vendendo na nossa plataforma	você fortalece a nossa rede e a nossa causa. O nosso propósito é tornar o mundo mais inclusivo e colaborativo, fomentando todo um ecossistema. Queremos criar negócios para pessoas com deficiência e necessidades específicas, dando a elas visibilidade e autonomia, apoiar os negócios das suas famílias, investir na capacitação para o empreendedorismo, auxiliar pais atípicos através da nossa rede de apoio, dar evidência a projetos  que  defendam  a  nossa  causa  e  incentivar  empresas a investirem  em  produtos inclusivos e adaptados, conscientizando sobre a importância da diversidade no ambiente corporativo.`,
		aboutNecessialDescription3: "Juntos podemos ser a mudança que queremos ver no mundo.",
		termsOfUse: {
			introduction: `A leitura, compreensão e aceitação destes Termos de Uso do Site e Condições Gerais
			são condições obrigatórias e indispensáveis para realizar o cadastro e utilizar os serviços
			da Necessial. Em caso de dúvidas, consulte a ajuda no site`,
			title1: "1. Objeto",
			step1: {
				introduction: `A Necessial é um marketplace de divulgação e venda de produtos. Os presentes termos
				regulam os serviços de intermediação oferecidos pela Necessial:`,
				firstTopic: ` a. Os serviços dos presentes Termos de Uso do Site e Condições Gerais consistem em ofertar e hospedar espaços no site para que os usuários vendedores anunciem seus próprios produtos, nos termos aqui descritos, e viabilizar o contato direto entre usuários compradores e usuários vendedores, interessados em adquirir os produtos anunciados.`,
				secondTopic: `b. A Necessial possibilita que os usuários se contatem e negociem entre si diretamente, sem intervir na negociação ou na efetivação dos negócios, não sendo, nesta qualidade, fornecedor de quaisquer produtos ou serviços anunciados pelos vendedores no site.`,
			},
			step1_1: `
			1.1. Todas as fases da negociação de compra e venda, desde o anúncio, até o pagamento devem ser integralmente realizadas pelos usuários dentro do site sendo vedada qualquer divulgação de contato pessoal durante a negociação, sob pena de aplicação das penalidades previstas nestes termos.`,
			step1_2: `
			1.2. O serviço oferecido pela Necessial é de intermediação de negócios, não nos figuramos como parte nas transações de compra e venda de produtos que se realizam entre os usuários. Toda a compra ou venda efetuada no site é de responsabilidade dos respectivos compradores e vendedores, sendo deles a responsabilidade por todas as obrigações delas decorrentes, sejam fiscais, trabalhistas, consumeristas ou de qualquer outra natureza.`,
			step1_3: `
			1.3. A Necessial recomenda que toda transação seja realizada com cautela, devendo o usuário avaliar os riscos da negociação, e se isenta de qualquer responsabilidade e obrigação pela disponibilidade e não entrega de produtos, falta de pagamento, pontualidade ou qualquer outro prejuízo gerado por usuários. Somente produtos definidos conforme as regras destes Termos de Uso do Site e
			Condições Gerais, podem ser anunciados na Necessial.`,
			title2: "2. Pagamento pela utilização dos serviços",
			step2_1: `2.1. As vendas feitas pelo vendedor na Necessial somente podem ser concretizadas com a utilização do meio de pagamento oferecido pelo site, nas modalidades, cartão e boleto`,
			step2_2: `2.2. Todas as vendas realizadas pela Necessial são comissionadas e a mesma retém uma taxa de 12% sobre o valor total da venda acrescido o valor do frete, no momento que o comprador paga pelo pedido, sendo que, a taxa cobrada pelo sistema de pagamentos já está inclusa na taxa total cobrada pela Necessial. `,
			title3: "3.Comunicação entre os usuários",
			step3_1: `3.1. Todo e qualquer pedido que for realizado na Necessial, tem obrigatoriamente que ser concluído pelo site, não sendo permitido que o vendedor direcione o comprador para outros meios fora do site, como outros sites, plataformas, mídias sociais e aplicativos de conversa, para conclusão da venda.`,
			step3_2: `3.2. A comunicação entre vendedor e comprador deve ocorrer através do chat de mensagens do site, desta forma todas as mensagens ficam armazenadas no site, com o objetivo do site de manter um histórico completo dos pedidos dentro do site e verificar eventuais infrações a estes Termos e Condições.`,
			step3_3: {
				introduction: `3.3. As informações divulgadas na Necessial pelos usuários vendedores e
				usuários compradores, devem seguir nosso código de conduta e estão proibidas
				as seguintes práticas de comunicação na loja e nas mensagens:`,
				firstTopic: "a. Conteúdo ofensivo ou hostil, que possa atentar contra a honra e dignidade de terceiros, ou que veicule, incite ou estimule a violência ou qualquer tipo de discriminação;",
				secondTopic: "b. Compartilhar outros domínios e lojas virtuais do vendedor que não o endereço de sua loja na Necessial;",
				thirdTopic: "c. Divulgação de e-mail, telefone ou endereço da loja;",
				fourthTopic: "d. Divulgação de endereço pessoal ou da loja em redes sociais (Instagram, Facebook, Twitter, Tiktok e outros);",
				fivethTopic: "e. Divulgação de dados bancários;",
			},
			title4: "4. Responsabilidade do site e dos usuários",
			step4_1: `4.1. O usuário é responsável por todas as atividades ocorridas com seu nome, por manter o sigilo e segurança de sua senha e seguir toda a legislação vigente, assim como todas as Políticas da Necessial.`,
			step4_2: `4.2. O usuário concorda em não usar nomes de marcas registradas, postar apenas textos e fotos produzidos por ele mesmo, e garante que é autor ou que tem os direitos autorais, ou de marca, de todo material que publicar na Necessial.`,
			step4_3: `4.3. O comprador que converter o seu cadastro para vendedor, autoriza que seu perfil se torne público. `,
			step4_4: `4.4. O vendedor autoriza a utilização, divulgação e publicação de seus anúncios, imagens utilizadas em seus anúncios e avaliações postadas de pedidos, a critério exclusivo da Necessial, em redes sociais, banners, links patrocinados, ou em quaisquer locais públicos ou privados como feiras, cartazes, revistas e outros meios de comunicação.`,
			title5: "5. Trocas e devoluções",
			step5_1: `5.1. O vendedor tem autonomia para definir a Política de Trocas e Devoluções de sua loja, que deve estar de acordo com a legislação vigente e com o Código de Defesa do Consumidor.`,
			step5_2: `5.2. O comprador deve acordar sobre a devolução de um produto diretamente com o vendedor, não havendo acordo ou retorno, o comprador deve abrir uma reclamação junto a Necessial.`,
			step5_3: `5.3. Se loja não tiver estabelecido uma Política de Trocas e Devoluções ou as mesmas estiverem em desacordo com a legislação vigente, será a aplicada a Política de Troca e Devoluções da Necessial.`,
			step5_4: `5.4. Cada vendedor é responsável pela gestão de sua loja e produtos dentro da Necessial. Sendo assim, a Necessial não tem envolvimento e é isenta de responsabilidade nas transações individuais entre comprador e vendedor, não sendo responsável pela garantia que um produto que não atenda as expectativas do comprador seja trocado, reembolsado ou devolvido.`,
			title6: "6. Armazenamento de dados",
			step6_1: `6.1. Não é permitida a utilização por parte dos usuários de nenhum recurso interfira nas atividades e operações da Necessial, bem como nos anúncios, descrições, contas ou seus bancos de dados.`,
			step6_2: `6.2. Qualquer tentativa ou atividade que viole ou contrarie as leis de direito de propriedade intelectual ou as condições estipuladas nestes Termos de Uso do Site, tornarão o responsável passível das ações legais pertinentes, sendo responsável pelas indenizações por eventuais danos causados.`,
			step6_3: `6.3. O usuário concorda que a Necessial não tem nenhuma responsabilidade pela exclusão, falha de armazenamento ou falha de transmissão de qualquer conteúdo ou outras comunicações mantidas pelo serviço.`,
			title7: "7. Privacidade de informação",
			step7_1: `7.1. Toda informação ou dado pessoal do usuário da Necessial é armazenada em
			servidores e a Necessial tomará todas as medidas para manter a sua confidencialidade
			e a segurança, porém não responderá por prejuízo que possa ser derivado da violação
			dessas medidas por parte de terceiros que utilizem as redes públicas ou a internet,
			subvertendo os sistemas de segurança para acessar as informações dos usuários.`,
			step7_2: `7.2. O usuário autoriza que suas informações e dados pessoais sejam fornecidos sempre
			que solicitado pelas autoridades competentes e informados em processos judiciais e
			administrativos.`,
			step7_3: `7.3. Em caso de dúvidas sobre proteção dos seus dados pessoais você deve acessar a
			nossa Política de Privacidade.`,
			title8: "8. Conteúdo do serviço",
			step8_1: `8.1. A Necessial não assume responsabilidade e não tem qualquer obrigação de monitorar o conteúdo de terceiros e se reserva o direito de remover ou se recusar a distribuir qualquer conteúdo que viole este Termos de Uso do Site e Condições Gerais.`,
			step8_2: `8.2. A Necessial poderá confirmar os dados pessoais informados e consultar entidades públicas, companhias especializadas ou bancos de dados, e está desde já autorizado a fazê-lo. A informação que a Necessial obtiver destas entidades será tratada de forma confidencial. A Necessial coleta e armazena automaticamente algumas informações sobre a atividade dos usuários cadastrados e visitantes de seu site. Para saber todos os detalhes sobre os seus dados você pode acessar a nossa Política de Privacidade.`,
			step8_3: {
				introduction: `8.3. A Necessial terá total acesso ao conteúdo das mensagens trocadas entre vendedores e compradores, assim como a descrição dos produtos e das lojas e poderá, a qualquer momento e a seu exclusivo critério:`,
				firstTopic: "a. Editar ou bloquear seu conteúdo ou excluir o cadastro de qualquer vendedor e comprador;",
				secondTopic: "b. Advertir a respeito de troca de informações contrárias aos Termos de Uso do Site e Condições Gerais; ",
				thirdTopic: "c. Divulgar o conteúdo em processo judicial ou administrativo para defesa de seus interesses ou de terceiros.",
			},
			title9: "9. Direitos de propriedade intelectual",
			step9_1: `9.1. A Necessial detém todos os direitos, títulos e interesses referentes ao serviço, incluindo todos os direitos de propriedade intelectual.`,
			step9_2: `9.2. Os direitos da Necessial estão protegidos por leis de propriedade intelectual nacionais e internacionais, e o usuário concorda que não copiará, reproduzirá, alterará, modificará ou criará produtos ou serviços derivados, sob pena de responder perante a  Necessial pelas perdas e danos que causar, incluindo honorários advocatícios, quando necessários para a defesa dos interesses da Necessial.`,
			step9_3: `9.3. A Necessial não reivindica nenhum direito de propriedade ou controle sobre qualquer conteúdo enviado,postado ou exibido pelo usuário. O usuário detém todas as patentes, marcas comerciais e direitos autorais de todo conteúdo enviado, publicado ou exibido na Necessial e é responsável pela devida proteção desses direitos. Ao enviar, publicar ou exibir o conteúdo na Necessial, o usuário isenta de royalties e autoriza a reprodução, publicação e distribuição pela Necessial destes conteúdos.`,
			title10: "10. Proibida a revenda do serviço",
			step10_1: `10.1. O usuário concorda em não reproduzir, duplicar, copiar, vender, trocar, revender ou explorar com quaisquer fins comerciais.`,
			title11: "11. Declarações e garantias",
			step11_1: `11.1. O usuário declara e garante que todas as informações fornecidas a Necessial estão corretas e atualizadas e que detêm todos os direitos, poderes e autoridade necessários para ingressar e utilizar o serviço oferecido pela Necessial.`,
			title12: "12. Encerramento, advertência e suspensão",
			step12_1: {
				introduction: `12.1. Sem prejuízo de outras medidas, A Necessial poderá advertir, suspender, bloquear ou excluir, temporária ou definitivamente, a conta de um usuário, a qualquertempo, e iniciar as ações legais cabíveis se:`,
				firstTopic: "a. O usuário não cumprir qualquer regra destes Termos de Uso do Site e Condições Gerais;",
				secondTopic: "b. Se descumprir com seus deveres de usuário;",
				thirdTopic: "c. Se praticar atos fraudulentos ou dolosos;",
				fourthTopic: "d. Se não puder ter sua identidade verificada ou se qualquer informação fornecida não for verdadeira;",
				fivethTopic: "e. Se os anúncios ou qualquer atitude do usuário tenham causado algum dano a terceiros ou a própria Necessial;",
				sixthTopic: "f. Se não estiver em dia com suas faturas de comissão por vendas;",
				seventhTopic: "g. Se o usuário solicitar a suspensão ou bloqueio de sua conta.",
			},
			step12_2: `12.2. Nos casos de suspensão ou bloqueio da conta do usuário, todos os anúncios
			ativos serão automaticamente cancelados.`,
			step12_3: `12.3. O usuário que teve sua conta bloqueada não será ressarcido de qualquer pagamento que tenha sido feito a Necessial, independentemente do tempo decorrido entre o último pagamento e o bloqueio da conta.`,
			title13: "13. Cancelamento dos serviços a pedido do usuário",
			step13_1: `13.1. Se o usuário desejar cancelar sua loja deverá realizar a solicitação diretamente com o nosso atendimento, sendo que qualquer pagamento que tenha sido feito neste período não será ressarcido, independentemente do tempo decorrido entre o último pagamento e o cancelamento da loja.`,
			step13_2: `13.2. Caso existam pendências de entrega, reembolso, fatura, entre outros, o
			cancelamento da conta do usuário dependerá da integral regularização destas
			pendências.`,
			title14: " 14. Isenção de responsabilidade",
			step14_1: `14.1. O usuário concorda em isentar e desresponsabilizar a Necessial e seus funcionários de queixas de terceiros causadas pela ou relacionadas à sua utilização do serviço, incluindo qualquer responsabilidade ou despesa proveniente de queixas, perdas, danos diretos e consequentes, ações judiciais, sentenças, despesas processuais ou honorários de qualquer tipo e natureza.`,
			step14_2: `14.2. A Necessial não se responsabiliza por eventuais diferenças nos valores informados e pagos pelo usuário comprador por conta de reajustes dos Correios. Os Correios podem realizar adequações nos preços dos serviços oferecidos a qualquer momento, e poderão surgir alterações nos valores de frete no intervalo de tempo do cálculo ou estimativa do frete pago pelo usuário comprador.`,
			step14_3: `14.3. As postagens realizadas pelos usuários utilizando o serviço dos Correios é de responsabilidade única e exclusiva dos mesmos, não podendo a Necessial ser responsabilizado por prejuízos decorrentes de perdas, furtos e danos decorrentes do uso destes serviços. Qualquer prejuízo causado pelos Correios, deve ser tratado diretamente com a empresa.`,
			title15: "15. Atualização e disponibilidade dos termos e condições gerais de uso do site",
			step15_1: `15.1. A Necessial poderá alterar, a qualquer momento, estes Termos de uso, com o objetivo de aprimorar os serviços prestados pelo site. Os novos Termos de uso entrarão em vigor em 15 (quinze) dias após publicados no site, caso não haja menção de outra data. No prazo de 30 (trinta) dias contados a partir da publicação das modificações, caso o usuário não concorde com os termos alterados, o mesmo deverá contatar nosso atendimento, com isso o vínculo contratual deixará de existir. Não havendo manifestação no prazo estipulado, compreendemos que o usuário aceitou os novos Termos de uso e o contrato continuará vinculando as partes.`,
			title16: "16. Disposições Finais",
			step16_1: `16.1. São partes integrantes e inseparáveis destes Termos de Uso do Site e Condições Gerais os documentos e/ou seções da Necessial incorporados por referência, onde estão detalhadas as políticas e/ou termos de diferentes serviços oferecidos pelo site. `,
			step16_2: `16.2. Este contrato constitui o acordo completo entre os usuários e a Necessial e rege seu uso do serviço, substituindo quaisquer contratos anteriores.`,
			step16_3: `16.3. Todos os itens destes Termos de uso são regidos pelas leis vigentes na República Federativa do Brasil. Para todos os assuntos referentes à interpretação, ao cumprimento ou qualquer outro questionamento relacionado a estes Termos de uso, as partes concordam em se submeter ao Foro da Comarca de Salvador.`,
			finalConsideration: "Em caso de dúvida, entre em contato com nosso atendimento.",
		},
		privacyPolicy: {
			step1: "A Necessial se preocupa com a privacidade dos seus dados e nosso compromisso é sempre tratar os seus dados pessoais com segurança, privacidade e transparência.",
			step2: `Esta política de privacidade descreve as informações e dados pessoais que coletamos, como eles são usados, armazenados e compartilhados,
			bem como os seus direitos em relação a esses dados, seja através do nosso site, aplicativos para mobile ou no compartilhamento das informações pessoais com outros agentes de tratamento.`,
			step3: `
			A leitura, compreensão e aceitação da política de privacidade e dos termos de uso são condições obrigatórias e indispensáveis para acesso e cadastro no nosso site e aplicativo,
			e ao aceitar os termos desta política de privacidade, a Necessial Atividades de Internet e Intermediação de Negócios Ltda, sob o CNPJ nº 42.752.845/0001-83,
			com endereço na Avenida Tancredo Neves. 2227, sub unidade 603, Caminho das Árvores, Salvador, Bahia, cep 41820-021, será responsável por todas as decisões sobre o tratamento dos seus dados e por responder às suas solicitações.
			`,
			step4: `
			Para vender e comprar na Necessial é necessário que você forneça alguns dados pessoais e ao navegar ou utilizar a nossa plataforma e alguns desses dados podem ser coletados para que possamos prestar nossos serviços de intermediação de compra e venda de produtos.
			Usamos seus dados de uma forma segura para melhorar a sua experiência na Necessial.
			`,
			step5: `
			Utilizamos cookies ou pixel tags para conhecer seus interesses, produtos visualizados, buscas realizadas, produtos favoritos, entre outros. Informações necessárias que permitem melhorar o nosso serviço, selecionar os melhores preços para você, escolher promoções e conteúdo publicitário personalizados.
			Todos esses dados pessoais serão utilizados conforme descrito na nossa política de privacidade que deve ser considerada como parte dos nossos termos de uso.`,
			step6: `A Necessial utiliza tecnologias e procedimentos adequados para proteger os dados coletados, com uma equipe responsável pela gestão desses dados em conformidade com as previsões legais e mudanças de tecnologia.
			Seus dados pessoais serão guardados nas hipóteses previstas em lei e pelo prazo estritamente necessário para cumprimento das finalidades de determinado tratamento ou legislação.`,
			step7: `Não compartilhamos seus dados pessoais com outros usuários, a não ser após a confirmação de compra e venda do produto anunciado na Necessial.
			O compartilhamento de dados entre usuário vendedor e usuário comprador tem como objetivo facilitar a troca de informações para a transação e finalização de pedidos e você se compromete a não utilizar os dados obtidos na
			Necessial para finalidades diferentes das descritas neste termo.`,
			step8: `A Necessial coopera com as autoridades competentes para garantir o cumprimento das leis e os dados coletados podem ser requeridos pelas autoridades judiciais ou governamentais, no âmbito de investigações conduzidas por estas.
			Poderemos também utilizar seus dados pessoais para o exercício regular de direitos da Necessial na apresentação, quando necessário, de documentos em processos judiciais, administrativos e arbitrais.`,
			step9: `A qualquer momento, você tem direito de solicitar mais informações sobre o tratamento de seus dados pessoais, ter acesso aos dados tratados, solicitar a correção de dados incompletos ou desatualizados,
			solicitar a eliminação destes dados e recusar o recebimento de informações e campanhas.`,
			step10: `Se após a leitura da nossa política de privacidade você não entender ou não concordar com algum ponto, orientamos que entre em contato com nosso atendimento antes de continuar com o seu cadastro.`,
		},
	},
};
