// MARK: Libraries
import moment from "moment";

export const general = {
	ptBR: {
		data: "Dados",
		close: "Fechar",
		doneOn: "Feito em ",
		soldIn: "Vendido em ",
		ok: "Ok",
		yes: "Sim",
		cases: "Projetos Lançados",
		no: "Não",
		total: "Total",
		search: "procurar",
		resultSearch: "Resultados para",
		resultCategory: "Categoria: ",
		stillHaveDoubts: "Ainda tem dúvidas?",
		visitStoreButton: "Visitar loja",
		cancel: "Cancelar",
		add: "Cadastrar",
		confirm: "Confirmar",
		loading: "Carregando",
		back: "Voltar",
		reload: "Recarregar",
		next: "Próximo",
		nextPage: "Próxima",
		seeMore: "Ver mais",
		edit: "Editar",
		delete: "Deletar",
		email: "E-mail",
		faqFull: "Ajuda - Perguntas Frequentes",
		added: "Adicionado",
		dontWaitToTalkWithUs: "Entre em contato com a gente!",
		contact: "Contato",
		contactSUccedExplain:
			"Sua mensagem foi enviada e em breve entraremos em contato!",
		contactSucceded: "Obrigado! Sua mensagem foi enviada.",
		removed: "Removido",
		copyLink: "Copiar link para Área de Transferência",
		backToHome: "Voltar para Home",
		help: "Ajuda",
		name: "Nome",
		nameOrStore: "Nome/Loja",
		pleaseTypeYourEmail:
			"Por favor, digite o e-mail correspondente a sua conta. ",
		title: "Título do Produto",
		storeName: "Nome da Loja",
		slug: "Apelido",
		editData: "Alterar",
		questions: "Questões",
		message: "Mensagem",
		IDontKnowMyCEP: "Não sei meu CEP",
		send: "Enviar",
		phone: "Número de contato",
		currentPassword: "Senha atual",
		thank: "Obrigado!",
		talkToUs: "Fale conosco",
		saveEdit: "Salvar Alterações",
		sideDrawer: "Menu Lateral",
		personalData: "Dados Pessoais",
		storeData: "Dados da Loja",
		login: "Login",
		purchaseSummary: "Resumo da compra",
		saleSummary: "Resumo da venda",
		statusTitle: "Status do pedido",
		youWillReceiveEmail:
			"Você receberá um e-mail com instruções para recuperar a sua senha.",
		changeStatusProduction: "Mudar status do pedido para em produção",
		changeStatusOnWay: "Mudar status do pedido para a caminho",
		changeStatusDelivered: "Mudar status do pedido para entregue",
		purchaseCart: "Cartão da compra",
		infoStore: "Informações da loja",
		infoBuyer: "Informações do cliente",
		doneIn: "terminado em",
		DueDateIn: "Vencimento em",
		noticeBankAccount: "A conta cadastrada precisa estar vinculada ao mesmo CPF/CNPJ cadastrado na Necessial.",
		boletoDescription:
			"Vencimento em 1 dia útil. A data de entrega será alterada devido ao tempo de processamento do Boleto.",
		paymentBillet: "Pagamento por boleto",
		payment: "Pagamento",
		whoReceived: "Quem recebe",
		observations: "Observações",
		registration: "Nova conta",
		formHeadTitle: {
			profile: "Dados de Perfil",
			address: "Dados do Endereço",
			bankAccount: "Dados de Conta Bancária",
		},
		registrationStore: "Cadastro da loja",
		productTitle: "Cadastre seu produto é muito rápido!",
		editProductTitle: "Editar Produto",
		secondProductTitle: "Lembre-se	 de escolher as melhores fotos",
		freightNotice: "O frete deverá ser combinado no chat da Necessial",
		packageInfoTitle:
			"Dimensões do pacote ou caixa, detalhes das dimensões do produto na descrição!",
		myProductsTitle: "Meus Produtos",
		myProductsAddButton: "Adicionar produto",
		myProductsEditDelete: "Editar / Deletar",
		error: "Erro",
		invalidCep: "CEP não localizado.",
		needBankAccount: "É necessário cadastrar uma conta bancária para prosseguir",
		password: "Senha",
		quantity: "Quantidade",
		shipping: "Frete",
		shippingSelect: "Selecione um frete:",
		shippingRadio: (title: string, daysToDelivery: number, price: string) =>
			`${title}, Prazo: ${daysToDelivery} dias, Valor: ${price}`,
		alreadyStore: "É vendedor? ",
		alreadyRegistered: "Já possui cadastro? ",
		cpf: "CPF/CNPJ",
		vendorPolicy: "Políticas da loja",
		options: "Opções",
		exit: "Sair",
		deleteUser: "Excluir minha conta",
		enterNow: "Entre agora",
		enter: "Entrar",
		nickExplanation:
			"O apelido será como irão te identificar na nossa plataforma.",
		slugExplanation:
			"O Apelido será como os outros identificarão sua loja na plataforma.",
		confirmYourPassword: "Confirme sua senha",
		importantTermsUse:
			"Se sua loja tiver alguma regra, é importante descrever aqui!",
		agreeWithThe: "Por favor, leia e concorde com os nossos ",
		useMyCpf: "Usar meu CPF",
		userTerms: "Termos de Uso",
		nick: "Apelido",
		description: "Descrição",
		categories: "Categorias",
		tags: "Tags",
		price: "Preço",
		shopping: "Compras",
		sales: "Vendas",
		slugProductExplanation:
			"Esse Apelido será como os outros identificarão seu produto na plataforma.",
		descriptionPlaceholder: "Conte um pouco sobre seu produto...",
		commentExplanationProduct: `Inclua apenas informações do produto: é melhor do que você esperava? quais são suas funcionalidades? É fácil de usar?
		Quanto mais informações, mais ajudará outros compradores.`,
		commentExplanationStore: `Inclua informações da loja: como foi o atendimento? os produtos são de qualidade? resolveram rápido seu problema?
		Quanto mais informações, mais ajudará outros compradores.`,
		cmLengthProductExplanation: "Valor mínimo de 15 cm e valor máximo de 100 cm",
		cmWidthProductExplanation: "Valor mínimo de 10 cm e valor máximo de 100 cm",
		cmHeightProductExplanation: "Valor mínimo de 1 cm e valor máximo de 100 cm",
		kgProductExplanation: "Peso máximo de 30 kg",
		timeProductionPlaceholder: "Ex: 8 dias",
		minAmountPlaceHolder: "Ex: 3 produtos",
		diameter: "Diâmetro",
		width: "Largura",
		height: "Altura",
		length: "Comprimento",
		continue: "Continuar",
		weigth: "Peso",
		selectImageProfile: "Clique para selecionar imagem de perfil",
		selectImage: "Selecione uma imagem",
		crop: "Cortar",
		cropImage: "Corte a imagem",
		improveVisualization:
			"Isso irá melhorar a visualização do produto na loja.",
		storePage: "Página da loja",
		chat: "Conversas",
		addedProduct: "Adicione seu produto!",
		orderHistory: "Meus pedidos",
		purchaseHistory: "Compras",
		orderHistoryStore: "Minhas Vendas",
		mySales: "Vendas",
		productionTime: "Tempo de Produção",
		delivery: "Entrega",
		minAmountLabel: "Quantidade mínima por compra",
		boutStore: "Conte um pouco sobre sua loja...",
		creating: "Criando",
		editing: "Editando...",
		adding: "Adicionando",
		creatingFirstUser: "Criando primeiro sua conta",
		dontHaveAnAccountYet: "Ainda não possui uma conta?",
		askHelp: "Ajuda - Perguntas Frequentes,",
		createAccountNow: "Crie uma conta agora!",
		success: "Sucesso!",
		changePasswordTitle: "Alterar senha",
		oldPassword: "Senha antiga",
		newPassword: "Nova senha",
		confirmPassword: "Confirme a senha",
		successMessage: "Operação realizada com sucesso",
		successRegister: "Cadastro feito com sucesso!",
		IWantToSell: "Quero vender",
		recovery: "Recuperar senha",
		ResetPassword: "Nova senha",
		createAccountStore: "Crie sua loja!",
		address: "Endereço",
		addressReceive: "Endereço de entrega",
		cep: "CEP",
		dontKnowYourZipcode: "Não sabe seu cep?",
		state: "Estado",
		city: "Cidade",
		streetNumber: "Número",
		neighborhood: "Bairro",
		aboutNecessial: "Sobre Nós",
		termsOfUse: "Termo de uso do site e condições gerais",
		privacyPolicyTitle: "Política de privacidade e confidencialidade da informação",
		aboutUs: "SOBRE NÓS",
		knowMore: "Saiba mais",
		street: "Rua",
		complementary: "Complemento",
		submitAddress: "Confirmar endereço",
		submitReviewStore: "Enviar avaliação da loja",
		sendReview: "Enviar avaliação",
		searchCepLink:
			// tslint:disable-next-line: no-http-string
			"https://buscacepinter.correios.com.br/app/localidade_logradouro/index.php",
		changeAddress: "Alterar",
		noAdress: "Ainda não há endereço cadastrado.",
		cnpj: "CNPJ",
		editProduct: "Editar produto",
		deleteProduct: "Apagar Produto",
		deleteProductMessage: (productName: string) =>
			`Tem certeza que deseja deletar o produto ${productName} da sua loja?`,
		buy: "Comprar",
		addToCart: "Adicione ao Carrinho",
		addedToCart: "Produto adicionado ao carrinho!",
		perUnity: "/ un",
		titleDescription: "Descrição",
		contactsUs: "Entre em Contato",
		store: "Loja",
		freeShipping: "Frete Grátis",
		storeEvaluation: (store: string) => `Avaliação da Loja - ${store}`,
		productTime: (time: string) => `Tempo de produção: ${time}`,
		minAmount: (quantidade: number) => `Quantidade minima: ${quantidade}`,
		checkoutParcel: (parcel: number, price: string) =>
			`${parcel}x ${price}`,
		removeFavoriteProduct: (productName: string) =>
			`Tem certeza que deseja remover o produto ${productName} dos seus favoritos?`,
		emptyFavoriteProduct:
			"Você ainda não adicionou nenhum produto aos seus favoritos",
		exploreProducts: "Explorar produtos",
		favorites: "Favoritos",
		seeDetails: "Ver detalhes",
		logo: "Logo",
		document: "CPF/CNPJ",
		selectShipping: "Selecione o frete",
		leadTime: "Prazo",
		value: "Valor",
		shippingMethod: "Método de envio",
		paymentMethod: "Método de Pagamento",
		selectInstallments: "Selecione uma parcela",
		creditCard: "Cartão de crédito",
		credit: "Crédito",
		expDate: "Data de Validade",
		selectCard: "Selecione um cartão",
		cardMask: (brand: string, lastDigits: string) =>
			`${brand} ****${lastDigits}`,
		addCard: "Adicionar Cartão",
		addCardButton: "Adicionar",
		remove: "Remover",
		removeCard: "Remover Cartão",
		deleteCard: "Excluir Cartão",
		deleteCardMessage: "Tem certeza que deseja excluir o cartão?",
		cardHolder: "Titular do Cartão",
		cardCvv: "CVV",
		number: "Número",
		changeMethod: "Alterar Método",
		titleProduct: "Produtos",
		subtotal: "Subtotal",
		ratingTitle: "Avaliações",
		ratingAverage: "Média de avaliações",
		noRating: "Não há avaliações no momento.",
		userNick: (nick: string) => `@${nick}`,
		freight: "Frete",
		pay: "Pagar",
		checkout: "Finalizar o pedido",
		summary: "Resumo",
		orderObservations: "Observações do pedido",
		orderSuccessMessage: "O pedido foi feito com sucesso!",
		shippingAdress: "Endereço de Envio",
		streetAdress: (street: string, num: string) => `${street}, ${num}`,
		cityAdress: (city: string, state: string) => `${city} - ${state}`,
		related: "Relacionados",
		commentProduct: "Fale um pouco sobre o produto",
		commentStore: "Fale sobre a loja",
		amountRating: "Quantas estrelas você daria?",
		previous: "Anterior",
		dateStore: (date: Date) =>
			`Necessial desde ${moment(date).format("YYYY")}`,
		vendorPolicyStore: (storeName: string) =>
			`Políticas da loja ${storeName}`,
		editStore: "Editar loja",
		cnpjOrCPF: "CNPJ / CPF",
		cancelOrder: "Esse pedido foi cancelado",
		productNotFoundToReview: "Esse produto não está disponível para avaliação.",
		notFoundProducts:
			"Não encontramos nadinha, mas não fica triste. Que tal fazer uma nova busca?",
		bankAccount: "Conta Bancária",
		bankCode: "Código do Banco",
		bankAccountType: "Tipo da Conta",
		selectBankAccountType: "Selecione um tipo de conta",
		noBankAccount: "Ainda não há conta bancária cadastrada.",
		bank: "Banco",
		account: "Conta",
		accountDv: "Dígito verificador da conta",
		legalName: "Titular da conta",
		agencyDv: "Dígito verificador da agência",
		agency: "Agência",
		confirmBankAccount: "Confirma conta bancária",
		finishRegister: "Concluir cadastro",
		editBankAccount: "Editar",
		bankName: "Banco",
		concatData: (left: string, right: string | null) =>
			right ? `${left} - ${right}` : left,
		myCart: "MEU CARRINHO",
		notFoundCurrentCart: "Você não tem nenhum item no carrinho",
		letsBuy: "Vamos comprar?",
		addedBankAccount: "Adicione uma conta",
		notFoundBankAccount: "Você possui uma conta bancária?",
		removeProduct: "Remover produto",
		removeProductMessage:
			"Tem certeza que deseja remover o produto do seu carrinho?",
		myEarnings: "Faturamento",
		evaluateProduct: "Avaliar",
		evaluateStore: "Avaliar loja",
		myProducts: "Meus produtos",
		availableValue: "Valor Disponível",
		pending: "Pendente",
		withdraw: "Sacar",
		bankData: "Dados Bancários",
		payments: "Pagamentos",
		balance: "saldo",
		emptyListTransferHistory: "Você não possui histórico de transferências",
		transfer: "Transferências",
		createBankAccountTip:
			"Cadastre uma conta bancária para poder sacar seu saldo.",
		transferred: "Transferido",
		waiting: "Pendente",
		available: "Disponível",
		showMore: "Mostrar mais",
		showLess: "Mostrar menos",
		about: "Sobre a Necessial",
		profile: "Perfil",
		facebook: "Facebook",
		instagram: "Instagram",
		twitter: "Twitter",
		utilities: "Utilitários",
		followUs: "Nos siga",
		seller: "Vendedor",
		didntFindWhatNeededContactTextPart1:
			`Se você não achou sua dúvida na nossa página de`,
		didntFindWhatNeededContactTextPart2:
			` pode entrar em contato com a gente, que responderemos o mais rápido possível!`,
		beASeller: "Seja vendedor",
		transferHistory: "Histórico de saques",
		logoutMessage: "Tem certeza que deseja sair?",
		deleteMessage: "Tem certeza que deseja deletar PERMANENTEMENTE essa conta?",
		welcomeUser: (user?: { name: string }) =>
			`Olá, ${user ? user.name : "bem-vindo"}`,
		privacyPolicy: "Políticas de Privacidade",
	},
};
