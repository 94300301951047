// MARK: Resources
import { alerts } from "./alerts";
import { ogTags } from "./ogTags";
import { components } from "./components";
import { error } from "./error";
import { home } from "./pages/home";
import { general } from "./general";
import { project } from "./project";
import { company } from "./company";

import LocalizedStrings from "localized-strings";

// MARK: Implementation
const currencyBrl = (
	cents: number,
	options?: { showSymbol?: boolean; withCents?: boolean },
) => {
	const withCents =
		options === undefined ||
		options.withCents === undefined ||
		options.withCents;
	const showSymbol =
		options === undefined ||
		options.showSymbol === undefined ||
		options.showSymbol;
	const numeroTokens: string[] = `${
		withCents ? (cents / 100).toFixed(2) : Math.round(cents / 100)
	}`.split(".");
	numeroTokens[0] = `${showSymbol ? "R$ " : ""}${numeroTokens[0]
		.split(/(?=(?:...)*$)/)
		.join(".")}`;
	return numeroTokens.join(",");
};

const Strings = new LocalizedStrings({
	"pt-BR": {
		company: company.ptBR,
		alerts: alerts.ptBR,
		ogTags: ogTags.ptBR,
		components: components.ptBR,
		error: error.ptBR,
		project: project.ptBR,
		pages: {
			home: home.ptBR,
		},
		general: general.ptBR,
		formatters: {
			currency: {
				BRL: currencyBrl,
			},
		},
		enum: {
			Category: {
				clothes: "Roupas",
				accessories: "Acessórios",
				sport: "Idoso",
				toys: "Infantil",
				party: "Casa",
				educational: "PCD",
				health: "Bem-estar",
				customized: "Outros",
			},
			OrderStatus: {
				pendingPayment: "Pagamento pendente",
				analysing: "Pagamento confirmado",
				production: "Em produção",
				onWay: "A caminho",
				delivered: "Entregue",
				canceled: "Cancelado",
			},
			PaymentMethod: {
				creditCard: "Cartão de crédito",
				boleto: "Boleto",
			},
			BankAccountType: {
				contaCorrente: "Conta corrente",
				contaPoupanca: "Conta poupança",
			},
			TransferStatus: {
				pendingTransfer: "Transferência pendente",
				transferred: "Transferido",
				failed: "Falhou",
				processing: "Em processamento",
				canceled: "Cancelado",
			},
		},
	},
});

export default Strings;
