export const currency = (centsValue: number) => {
	return (centsValue / 100).toLocaleString(
		window !== undefined ?
			window.navigator.language :
			"pt-BR",
		{
			currency: "BRL",
			style: "currency",
			maximumFractionDigits: 2,
			minimumFractionDigits: 2,
		},
	);
};

export const decimal = (value: number) => {
	return (value / 100).toLocaleString(
		"pt-BR",
		{
			style: "decimal",
			maximumFractionDigits: 2,
			minimumFractionDigits: 2,
		},
	);
};

export const currencyForBR = (centsValue: number) => {
	return (centsValue / 100).toLocaleString(
		"pt-BR",
		{
			currency: "BRL",
			style: "currency",
			maximumFractionDigits: 2,
			minimumFractionDigits: 2,
		},
	);
};

export const formatCep = (valor: string) => valor.replace(
	/(\d{5})(\d{3})/g, "$1-$2",
);
